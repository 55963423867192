<template>
    <div class="aiAuthenticationList">
      <div class="search">
        <div class="searchRadio">
          <el-radio-group v-model="searchRadio" size="medium">
            <el-radio :label="3">全部</el-radio>
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="1">已通过</el-radio>
            <el-radio :label="2">未通过</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="main">
        <el-table
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          :row-key="rowKey"
          border
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="chain_name" label="企业名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="pic" label="公司logo">
            <template slot-scope="scope" v-if="scope.row.pic">
              <el-image :src="scope.row.pic" class="pic"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="申请账号"> </el-table-column>
          <el-table-column prop="created" label="申请时间" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="is_enable" label="状态">
            <template slot-scope="scope">
              {{ enableObj[scope.row.is_enable] }}
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="220">
            <template
              slot-scope="scope"
              v-if="scope.row.shop_user_ai_chain_mid_id"
            >
              <el-button
                class="operationBtn"
                type="primary"
                size="mini"
                @click="handleLook(scope.row)"
                >查看</el-button
              >
              <el-button
                class="operationBtn"
                type="primary"
                size="mini"
                @click="handlePass(scope.row)"
                :disabled="scope.row.is_enable !== 0"
                >通过</el-button
              >
              <el-button
                class="operationBtn"
                :disabled="scope.row.is_enable !== 0"
                size="mini"
                type="warning"
                @click="onReject(scope.row)"
                >拒绝</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
  
      <el-dialog title="提示" :visible.sync="isReject" width="40%">
        <div class="flex">
          <span class="rejectLabel">拒绝理由：</span>
          <el-Input v-model="rowData.is_enable_content"></el-Input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleReject">确 定</el-button>
        </span>
      </el-dialog>
  
      <el-dialog :visible.sync="isLook" width="70%">
        <el-row class="lookInfo">
          <el-col :span="24">
            <span>统一社会信用代码:</span>
            <span>{{ rowData.grade }}</span>
          </el-col>
          <el-col :span="8">
            <div>
              <span class="lookLabel">企业名称：</span>
              <span>{{ rowData.chain_name }}</span>
            </div>
            <div>
              <span class="lookLabel">公司地区：</span>
              <span>{{ rowData.address }}/{{ rowData.job_district }}</span>
            </div>
            <div>
              <span class="lookLabel">融资阶段：</span>
              <span>{{ rowData.job_financing }}</span>
            </div>
            <div>
              <span class="lookLabel">单双休：</span>
              <span>{{ rowData.job_is_days }}</span>
            </div>
            <div>
              <span class="lookLabel">发展历程：</span>
              <span>{{ rowData.job_course }}</span>
            </div>
            <div>
              <span class="lookLabel">企业介绍：</span>
              <span>{{ rowData.introduce }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span class="lookLabel">企业负责人：</span>
              <span>{{ rowData.principal }}</span>
            </div>
            <div>
              <span class="lookLabel">详细地址：</span>
              <span>{{ rowData.job_street }}</span>
            </div>
            <div>
              <span class="lookLabel">公司行业：</span>
              <span>{{ rowData.job_industry }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span class="lookLabel">HR联系方式：</span>
              <span>{{ rowData.contact_way }}</span>
            </div>
            <div>
              <span class="lookLabel">企业规模：</span>
              <span>{{ rowData.job_scale }}</span>
            </div>
            <div>
              <span class="lookLabel">上下班时间：</span>
              <span>{{ rowData.job_time }}</span>
            </div>
            <div>
              <span class="lookLabel">公司LOGO：</span>
              <el-image style="width: 100px; height: 100px" :src="rowData.pic">
              </el-image>
            </div>
            <div>
              <span class="lookLabel">营业执照：</span>
              <el-image
                style="width: 100px; height: 100px"
                :src="rowData.business_license"
                :preview-src-list="[rowData.business_license]"
              >
              </el-image>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { getChainMid, updateChainMid } from "@/request/api";
  export default {
    data() {
      return {
        searchRadio: 3,
        tableData: [],
        data: [], // 原始数据
        enableObj: {
          0: "待审核",
          1: "已通过",
          2: "未通过",
        },
        isReject: false,
        rowData: {
          is_enable_content: "",
        },
        isLook: false,
      };
    },
    watch: {
      searchRadio() {
        this.init();
      },
    },
    methods: {
      async init() {
        const result = await getChainMid(this.searchRadio);
        if (result.data.code === "1000") {
          const data = result.data.data.map((item) => {
            item.id = new Date().getTime() + item.grade;
            item.children.map((child) => {
              child.id = new Date().getTime() + child.shop_user_ai_chain_mid_id;
              child.grade = item.grade;
              return child;
            });
            return item;
          });
          this.data = data;
          this.tableData = data;
        } else {
          this.$message.error(result.data.msg);
        }
      },
      rowKey(row) {
        return row.id;
      },
      async onUpdateChainMid(body) {
        const res = await updateChainMid({
          shop_user_ai_chain_mid_id: body.shop_user_ai_chain_mid_id,
          is_enable: body.is_enable,
          is_enable_content: body.is_enable_content,
          isNew: true,
        });
  
        if (res.data.code === "1000") {
          if (body.is_enable === 1) {
            this.$message({
              type: "success",
              message: "审批已通过",
            });
          } else {
            this.$message({
              type: "success",
              message: "审批已拒绝",
            });
          }
        }
        this.isReject = false;
        this.init();
      },
      // 审批通过
      handlePass(row) {
        this.$confirm("确认通过此申请?", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // TODO: 审批通过
            this.onUpdateChainMid({ ...row, is_enable: 1 });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审批",
            });
          });
      },
      onReject(row) {
        this.rowData = row;
        this.isReject = true;
      },
      handleReject() {
        this.onUpdateChainMid({ ...this.rowData, is_enable: 2 });
      },
      handleLook(row) {
        this.rowData = row;
        this.isLook = true;
      },
    },
    mounted() {
      this.init();
    },
  };
  </script>
  <style lang="less" scoped>
  .aiAuthenticationList {
    .search {
      width: 100%;
      background: #fff;
      .searchRadio {
        padding: 20px;
      }
    }
    .main {
      width: 96%;
      margin: 0 auto;
      background: #fff;
      margin-top: 30px;
    }
    .rejectLabel {
      display: inline-block;
      width: 100px;
      line-height: 40px;
    }
    .lookInfo {
      font-size: 16px;
      line-height: 40px;
    }
    .lookLabel {
      color: #000;
    }
    .operationBtn {
      margin-bottom: 20px;
    }
    .pic {
      width: 50px;
      height: 50px;
    }
  }
  </style>
  